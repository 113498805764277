import type {
  ComponentType,
  Component,
} from '@wix/ambassador-app-service-webapp/types';
import type { ComponentRef } from '@wix/yoshi-flow-editor';
import {
  HOTELS_MULTI_DASHBOARD_COMPONENT_ID,
  HOTEL_RUNNER_APP_ID,
  STAGING_HOTELS_RUNNER_APP_ID,
  STAGING_HOTELS_SEARCH_WIDGET,
} from '../../app-specific-logic/hotels/hotels.constants';
import { TOKEN } from '../../consts';
import { getGlobals } from '../../utils/globals.utils';

export const dashboardAction = ({
  multiDashboardData,
  componentRef,
}: {
  multiDashboardData?: string | undefined;
  componentRef: ComponentRef;
}) => {
  const {
    editorSDK,
    customElementSDK,
    instanceId,
    appData: { appDefinitionId },
  } = getGlobals();
  const multidashboardTwicks: Record<string, string> = {
    [STAGING_HOTELS_RUNNER_APP_ID]: encodeURIComponent(
      `${STAGING_HOTELS_SEARCH_WIDGET}|0`,
    ),
    [HOTEL_RUNNER_APP_ID]: encodeURIComponent(
      `${HOTELS_MULTI_DASHBOARD_COMPONENT_ID}|0`,
    ),
  };

  let multidashboardPath = '';

  if (multiDashboardData) {
    multidashboardPath = `/${encodeURIComponent(multiDashboardData + '|0')}`;
  }

  if (multidashboardTwicks[appDefinitionId]) {
    multidashboardPath = `/${multidashboardTwicks[appDefinitionId]}`;
  }

  const pathToDashboard = `app/${appDefinitionId}${multidashboardPath}?instance=${instanceId}`;

  return editorSDK.editor
    .openDashboardPanel(TOKEN, {
      url: pathToDashboard,
      closeOtherPanels: true,
    })
    .then(() => {
      customElementSDK.updateInstanceId();
    });
};

export const hasExternalDashboardUrl = (
  appData: Partial<{
    components: { type: ComponentType; data: { embedded?: boolean } }[];
  }>,
): boolean => {
  const firstDashboardComp: any = appData.components?.find(
    (comp: any) => comp.type === ('DASHBOARD' as ComponentType.DASHBOARD),
  );

  return !!(firstDashboardComp && !firstDashboardComp?.data?.embedded);
};

export async function openDashboard(componentRef: ComponentRef) {
  const { editorSDK, customElementSDK, appData } = getGlobals();

  // first find multiDashboard comp type and send back the comp id. we dont care about multi dash... just give back comp id for first dashboard
  const firstDashboardComp: any = appData.components?.find(
    (comp: any) => comp.type === ('DASHBOARD' as ComponentType.DASHBOARD),
  );

  const externalLink: string = firstDashboardComp?.data?.url || '';

  if (hasExternalDashboardUrl(appData)) {
    editorSDK.editor
      .openModalPanel(TOKEN, {
        height: 768,
        width: 1366,
        url: externalLink,
      })
      .then(() => {
        customElementSDK.updateInstanceId();
      });
  } else {
    // need to delete this type is wrong but this is for refactor
    const dashboard = appData.components?.find(
      (comp: Component) =>
        comp.compType ===
        ('MULTIPLE_DASHBOARDS' as ComponentType.MULTIPLE_DASHBOARDS),
    );

    dashboardAction({
      // @ts-expect-error
      multiDashboardData: dashboard && dashboard?.compId,
      componentRef,
    });
  }
}
