import type { gfppOriginStrings } from '@wix/platform-editor-sdk';
import type {
  ComponentRef,
  FlowAPI,
  FlowEditorSDK,
} from '@wix/yoshi-flow-editor';
import type { WebComponent } from '@wix/custom-element-sdk';
import { APESTER_APP_ID } from '../../app-specific-logic/app-ids';
import { appSettingsClickOnSettingsButton } from '@wix/bi-logger-custom-elements/v2';
import { TOKEN } from '../../consts';
import webComponent from '../../components/webComponent/.component.json';
import { getGlobals } from '../../utils/globals.utils';
import { HOTELS_APP_IDS } from '../../app-specific-logic/hotels/hotels.constants';

export const panelUrlBuilder = ({
  componentRef,
  isApester,
  compId,
  version,
  metaSiteId,
  tagName,
}: {
  componentRef: ComponentRef;
  isApester: boolean;
  compId?: string;
  version: string;
  metaSiteId: string;
  tagName: string;
}) => {
  const { editorSDK, customElementSDK, editorOrigin } = getGlobals();
  // TODO: base url?
  const apiBaseUrl = customElementSDK.getApiBaseUrl();
  const apesterSettingsURL = `https://editor.wixapps.net/render/prod/settings/web-component-wrapper/${process.env.ARTIFACT_VERSION}/${webComponent.id}`;
  const settingsUrl = isApester
    ? apesterSettingsURL
    : `${apiBaseUrl}/_serverless/dynamic-settings-renderer`;

  return `${settingsUrl}?wix-sdk-version=${getEditorSdkSource(
    editorSDK,
  )}&componentId=${
    componentRef.id
  }&compId=${compId}&version=${version}&metaSiteId=${metaSiteId}&tagName=${tagName}&origin=${JSON.stringify(
    editorOrigin,
  )}`;
};

export async function openSettingsPanel(componentRef: ComponentRef) {
  const { editorSDK, customElementSDK, appData, instanceId } = getGlobals();
  const controllerData = await editorSDK.document.controllers.getData(TOKEN, {
    controllerRef: componentRef,
  });

  const webComponent: WebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string; componentId: string }) =>
          comp.type === 'WEB' &&
          comp.componentId === controllerData?.config?.componentId,
      )) ||
    ({} as WebComponent);

  /**
   * Important TODO:
   * This "If" is responsible for managing the flow of using our Custom Element with an external settings panel (iFrame)
   * Originally (and currently only) used by Elfsight's Google Reviews: 3a5ec2c4-d646-4b49-b48c-9a9d4c1099d4
   *
   * The idea is we use the already existing settingsUrl field in the Web.proto:
   * https://github.com/wix-private/devcenter/blob/master/app-service/app-service-api/src/main/proto/components_catalog/components_catalog_data/web.proto#L18-L19
   * Since this field became meaningless and with no client representation once the Dynamic Settings feature was released.
   *
   * So, the flow is - widget has a settings url defined? open a modal with his url in an iFrame on editor, then re-render the widget once the modal is closed.
   * Otherwise, open Dynamic Settings as usual.
   *
   * But, since Hotels and Apester use our Custom Element since almost the beginning, they have a URL defined in their settingsUrl field (should be legit to remove).
   * That is why i excluded them specifically inside the if statement. Once their settingsUrl is deleted, the if statement can be updated.
   */
  if (
    webComponent?.data?.settingsUrl &&
    ![...HOTELS_APP_IDS, APESTER_APP_ID].includes(appData.appDefinitionId)
  ) {
    const { height: browserHeight, width: browserWidth } =
      await editorSDK.document.environment.screen.getScreenResolution();

    const dataEndpointURL = webComponent?.data?.gfppSettings?.fetchInitialData;

    editorSDK.editor
      .openModalPanel(TOKEN, {
        height: browserHeight * 0.8,
        width: browserWidth * 0.9,
        url: `${webComponent?.data?.settingsUrl}?instanceId=${instanceId}&compId=${componentRef.id}`,
        centered: true,
      })
      .then(() =>
        customElementSDK.triggerComponentRender({
          controllerRef: componentRef,
          dataEndpointURL,
        }),
      );
  } else {
    // get application data to set the title, height, width
    editorSDK.editor.openComponentPanel(TOKEN, {
      title: webComponent?.data?.modalTitle ?? 'Settings',
      url: panelUrlBuilder({
        componentRef,
        isApester: appData?.appDefinitionId === APESTER_APP_ID,
        compId: webComponent.componentId,
        version: appData.version as string,
        metaSiteId: await editorSDK.info.getMetaSiteId(TOKEN),
        tagName: (await getCustomElementTagName(editorSDK, componentRef, [
          webComponent,
        ])) as string,
      }),
      height: 582,
      width: 402,
      componentRef,
      helpId: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
    });
  }
}

export const reportBiEventGfppSettingsClicked = async (
  flowAPI: FlowAPI,
  widgetGfppClickedEvent: CustomEvent<{
    id: string;
    componentRef: ComponentRef;
    gfppOrigin: gfppOriginStrings;
  }>,
  webComponents: WebComponent[],
) => {
  const { editorSDK } = getGlobals();
  const { detail } = widgetGfppClickedEvent;

  flowAPI.bi?.report(
    appSettingsClickOnSettingsButton({
      app_id: await editorSDK.info.getAppDefinitionId(TOKEN),
      msid: await editorSDK.info.getMetaSiteId(TOKEN),
      element_type: await getCustomElementTagName(
        editorSDK,
        detail.componentRef,
        webComponents,
      ),
    }),
  );
};

const getEditorSdkSource = (editorSDK: FlowEditorSDK) => {
  return editorSDK.info.getSdkVersion(TOKEN).scriptSrc;
};

export const getCustomElementTagName = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  webComponents: WebComponent[],
) => {
  const { controllerType: componentId }: any =
    await editorSDK.components.data.get(TOKEN, {
      componentRef,
    });
  const relevantComponent = webComponents.find(
    (webComp) => webComp.componentId === componentId,
  );

  return relevantComponent?.data?.tagName;
};
