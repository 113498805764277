import type { MainActionDefinition } from '@wix/platform-editor-sdk';
import type { AppManifestBuilder } from '@wix/yoshi-flow-editor';
import type { WebComponent } from '@wix/custom-element-sdk';
import {
  HOTELS_BOOKING_PAGES,
  HOTEL_RUNNER_APP_ID,
} from '../../app-specific-logic/hotels/hotels.constants';
import { hasExternalDashboardUrl } from '../../lib/panel-flows/dashboard';
import { DASHBOARD_ACTION_ID, SETTINGS_ACTION_ID } from '../../consts';
import componentJson from './.component.json';
import { getGlobals } from '../../utils/globals.utils';

export async function configureWidgetManifest(
  appManifestBuilder: AppManifestBuilder,
) {
  const { customElementSDK, appData } = getGlobals();
  const webComponent: WebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string }) => comp.type === 'WEB',
      )) ||
    ({} as WebComponent);

  const webComponents: WebComponent[] =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  const { connectLabel } = webComponent.data || {};
  const hasExternal = hasExternalDashboardUrl(appData);
  const isUpgrade: boolean = await customElementSDK.isEligibleForUpgrade();

  // TODO : leaving this for now until we check the behavior for HotelRunner Staging and Apester
  appManifestBuilder.configureWidget(componentJson.id, (widgetBuilder) => {
    widgetBuilder.set({
      nickname: componentJson.id,
      displayName: componentJson.id,
    });
    widgetBuilder
      .gfpp()
      .set('mainAction1', { actionId: SETTINGS_ACTION_ID, label: 'Settings' })
      .set('mainAction2', { behavior: 'HIDE' })
      .set('help', {
        id: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
      })
      .set('animation', { behavior: 'HIDE' });
  });

  webComponents.forEach((comp) => {
    const { componentId } = comp;
    const dashboardButtonBehavior: MainActionDefinition | any = hasExternal
      ? {
          behavior: 'HIDE',
        }
      : {
          actionId: DASHBOARD_ACTION_ID,
          label: 'Manage',
        };

    const upgradeButtonBehavior =
      isUpgrade && appData.appDefinitionId !== HOTEL_RUNNER_APP_ID
        ? 'DEFAULT'
        : 'HIDE';

    appManifestBuilder.configureWidget(
      componentId ?? 'webComponent',
      (widgetBuilder) => {
        widgetBuilder.set({
          displayName: comp.data?.widget?.name ?? componentId,
          nickname: componentId,
        });
        // TODO: remove this when we get widget behavior from csm
        // specific case for hotel runner bookings page
        componentId &&
          HOTELS_BOOKING_PAGES.includes(componentId) &&
          widgetBuilder
            .behavior()
            .set({ duplicatable: false, removable: false });
        widgetBuilder
          .gfpp()
          .set('mainAction1', {
            actionId: SETTINGS_ACTION_ID,
            label: connectLabel || 'Settings',
          })
          .set('mainAction2', dashboardButtonBehavior)
          .set('help', {
            id: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
          })
          .set('upgrade', {
            behavior: upgradeButtonBehavior,
          })
          .set('animation', { behavior: 'HIDE' })
          .set('connect', { behavior: 'HIDE' });
      },
    );
  });
}
