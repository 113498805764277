import type { WebComponent } from '@wix/custom-element-sdk';
import { install } from './install-editor-actions';
import {
  filterPage,
  filterWidgets,
  findPagesThatHaveNotBeenInstalledInThePast,
} from './install.utils';

export const firstInstallFlow = async ({
  webComponents,
}: {
  webComponents: WebComponent[];
}) => {
  const webComponentsPages = filterPage(webComponents as WebComponent[]);
  const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
    webComponentsPages as WebComponent[],
  );
  const widgetsToAdd = pagesToAdd.length
    ? []
    : filterWidgets(webComponents as WebComponent[]);

  await install({
    pagesToAdd,
    widgetsToAdd,
  });
};
